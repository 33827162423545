<template>
  <CCard class="notice_card">
    <CCardBody>       
      <div class="d-flex align-items-center">
        <div class="flex-grow-1">
          <span class="d-block mb-1"><b>{{$t('communications.Build_a_template_for_your_moment')}}</b></span>
          <span>{{$t('communications.Email_editor_instructions')}}</span>
        </div>
        <div class="p-3 pt-0 pb-0">
          <CButton class="m-0" color="primary" @click="openInstructionVideo()">
            <span><i class="fa-brands fa-youtube fit-content mr-1"></i>{{$t('common.View_instruction_video')}}</span>
          </CButton>
        </div>
        <div class="align-self-start">
          <span class="pointer" @click="markInstructionsAsRead()"><i class="fas fa-times"/></span>
        </div>          
      </div>                
    </CCardBody>
  </CCard>
</template>

<script>

export default {
  name: 'EmailEditorInstructions',  
  methods: {
    markInstructionsAsRead() {
      // Set emailEditorInstructionsRead item in localStorage
      localStorage.setItem('emailEditorInstructionsRead', 'Y');
      // Emit the email_instructions_read event 
      this.$bus.$emit('email_instructions_read');      
    },
    openInstructionVideo() {
      window.open('https://youtu.be/SUAMuY0FZaQ?t=42', '_blank');
    }
  }
}
</script>